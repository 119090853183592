import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtk6 { color: #F78C6C; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`I managed to clear out 18.000 unused / invalid rows from the `}{`\``}{`wp2_icl_translations`}{`\``}{` table, after realizing a lot of old/expired translations was present in the table. This can happen if you're doing huge import/exports to your site, or delete products/pages/posts manually (via SQL) and also are using WPML.`}</p>
    <p>{`Run below query on your database to delete unused rows (rows that point to a page/post that no longer exists in the database). `}<strong parentName="p">{`Remember to backup your table before doing this, even though I would say it's pretty safe to run it.`}</strong></p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "sql",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`DELETE`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` wp2_icl_translations `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`FROM`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` wp2_icl_translations`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`LEFT JOIN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` wp2_posts p `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`ON`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` p.id `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` element_id`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`WHERE`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` element_type `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`IN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` (`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_product_variation'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`, `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_product'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`, `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_post'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`, `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_page'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`and`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` p.id `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`is`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`null`}</span></span></span></code></pre>
    <p>{`If you'd like to see which rows would be deleted first, you could run this query and have a look at the results:`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "sql",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`SELECT`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` element_id, element_type, p.id `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`as`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` post_id, p.post_title, p.post_type, p.post_date `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`FROM`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` wp2_icl_translations`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`LEFT JOIN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` wp2_posts p `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`ON`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` p.id `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` element_id`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk6"
            }}>{`WHERE`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` element_type `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`IN`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` (`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_product_variation'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`, `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`'`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`post_product'`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`) `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`and`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` p.id `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`is`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk6"
            }}>{`null`}</span></span></span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      